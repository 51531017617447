<template>
  <div class="index-container">
    待定
  </div>
</template>
<script>
export default {
  components: { },
  data() {
    return {
    };
  },
  methods: {
    
  },
  mounted() {
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
/deep/.el-card__header {
  font-size: 14px;
  border-bottom: 0px;
}
/deep/.el-card__body {
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
}
/deep/.el-tabs__item.is-active {
  color: #ee7031;
}
/deep/.el-tabs__item:hover {
  color: #ee7031;
}
/deep/.el-tabs__active-bar {
  background-color: #ee7031;
}
.index-container {
  height: 100%;
  font-size: 20px;
}
</style>